.login-page {
  background: url('/images/BG_green.png') no-repeat center/cover;

  input {
    border: solid 1px #fff;
    color: #000;
    background: #fff;
    line-height: 2;
  }

  .login-form {
    padding-top: 18%;
    .title {
      text-align: left;
      padding: 0 0 0 20px;
      .system-name {
        font-size: 35px;
      }
      .form-title {
        font-size: 70px;
      }
      h1, h3 {
        color: #000;
      }
    }
  }
}

.forgot-page {
  background-color: #1C1E35;

  .forgot-form {
    padding-top: 25%;
    .title {
      text-align: left;
      padding: 0 0 20px 20px;
      h1, h3 {
        color: white;
      }
    }

    .success-icon {
      width: 100px;
    }

    button {
      margin-top: 20px;
      text-transform: uppercase;
    }
  }
}